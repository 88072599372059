<template>
  <div class="peohistoristate">
    <el-card class="box-card father-card" shadow="never">
      <!-- 头部区域 -->
      <el-row class="btns">
        <el-form ref="form" :model="form" inline>
          <!-- 搜索框 -->
          <el-form-item label="人员名称">
            <el-input v-model="form.userName" placeholder="请输入人员名称" clearable></el-input>
          </el-form-item>
          <el-form-item label="人员类型">
            <el-select v-model="form.personType" placeholder="请选择人员类型" filterable clearable>
              <el-option
                :label="item.label"
                :value="item.status"
                v-for="(item, index) in personTypeList"
                :key="index"
              ></el-option>
            </el-select>
          </el-form-item>
          <!-- 按钮区域 -->
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" @click="search()">搜索</el-button>
          </el-form-item>
        </el-form>
      </el-row>
      <!-- 表格区域 -->
      <el-row class="tab">
        <el-table
          ref="multipleTable"
          :data="tabData"
          tooltip-effect="dark"
          style="width: 100%"
          highlight-current-row
          stripe
          border
          :header-cell-style="rowClass"
        >
          <el-table-column prop="personName" label="人员名称" align="center"></el-table-column>
          <el-table-column prop="corpName" label="所属机构名称" align="center"></el-table-column>
          <el-table-column prop="personType" label="类型" align="center">
            <template slot-scope="scope">
              <span effect="dark" v-if="scope.row.personType==='0'">{{ '驾驶员' }}</span>
              <span type="success" effect="dark" v-else-if="scope.row.personType==='1'">{{ '医护人员' }}</span>
              <!-- <span type="danger" effect="dark" v-else-if="scope.row.personType==='2'">{{ '护送员' }}</span>
              <span
                type="warning"
                effect="dark"
                v-else-if="scope.row.personType==='3'"
              >{{ '搬抬人员' }}</span>
              <span type="warning" effect="dark" v-else>{{ '驾驶员' }}</span> -->
            </template>
          </el-table-column>
          <el-table-column prop="status" label="状态" align="center">
            <template slot-scope="scope">
              <span effect="dark" v-if="scope.row.status==='1'">{{ '在岗' }}</span>
              <span type="info" effect="dark" v-else-if="scope.row.status==='0'">{{ '不在岗' }}</span>
              <span type="success" effect="dark" v-else>{{ '已接单' }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="remark" label="备注" align="center"></el-table-column>
          <el-table-column prop="createTime" label="创建时间" align="center"></el-table-column>

          <el-table-column label="操作" align="center" min-width="150px">
            <template slot-scope="scope">
              <el-button size="mini" type="success" @click="look(scope.row)">查看详情</el-button>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页区域 -->
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handlePageNumChange"
          :current-page="paging.PageNo"
          :page-sizes="[10,15, 20, 50]"
          :page-size="paging.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </el-row>
      <!-- 机构详情弹出框 -->
      <el-dialog :visible.sync="orderDialogFormVisible" class="order-dialog">
        <span slot="title" class="dialog-Title">随车人员上下线详情</span>
        <el-form :model="selectForm" inline ref="selectForm" :rules="selectForm" class="selectForm">
          <!-- 第一行 -->
          <el-row>
            <el-form-item label="人员名称" prop="userName">
              <el-input v-model="selectForm.userName" autocomplete="off" disabled></el-input>
            </el-form-item>
            <el-form-item label="所属机构名称" prop="corpName">
              <el-input v-model="selectForm.corpName" autocomplete="off" disabled></el-input>
            </el-form-item>
            <el-form-item label="随车人员类型" prop="docType">
              <el-select
                v-model="selectForm.personType"
                placeholder="请选择随车人员类型"
                filterable
                clearable
                disabled
              >
                <el-option
                  :label="item.label"
                  :value="item.status"
                  v-for="(item, index) in personTypeList"
                  :key="index"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="状态" prop="status">
              <el-select
                v-model="selectForm.status"
                placeholder="请选择状态"
                filterable
                clearable
                :disabled="isDisabled"
              >
                <el-option
                  :label="item.label"
                  :value="item.status"
                  v-for="(item, index) in statusList"
                  :key="index"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="备注" prop="remark">
              <el-input v-model="selectForm.remark" autocomplete="off" disabled></el-input>
            </el-form-item>
          </el-row>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="sure()">确 认</el-button>
        </div>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import _ from 'lodash'
export default {
  name: 'peohistoristate',
  components: {
  },
  data () {
    return {
      tabData: [], // 表格数据源
      form: { userName: '', personType: '', personId: '' }, // 搜索表单
      total: 0, // 总数据条数
      paging: {
        pageSize: 10, // 每页显示数据条数
        PageNo: 1 // 当前页数
      }, // 分页表单
      orderDialogFormVisible: false, // 控制详情对话框的显示与隐藏
      selectForm: {}, // 选中的表单对象
      isDisabled: true, // 控制对话框的编辑
      statusList: [{ label: '在岗', status: '1' }, { label: '不在岗', status: '0' }, { label: '已接单', status: '2' }],
      //   personTypeList: [{ label: '医生', status: '0' }, { label: '护士', status: '1' }, { label: '护送员', status: '2' }, { label: '搬抬人员', status: '3' }, { label: '驾驶员', status: '9' }]// 类型
      personTypeList: [{ label: '驾驶员', status: '0' }, { label: '医护人员', status: '1' }]// 类型

    }
  },
  created () {
    this.getAllCar()
  },
  methods: {
    // 给表格头部设定背景色
    rowClass ({ row, rowIndex }) {
      return 'background:#e4eaec'
    },
    // 获取人员上下线历史列表
    async getAllCar () {
      const { data: res } = await this.$http.post('/pmService/tInfoPersonStatusHistory/getTInfoPersonStatusHistoryList', {
        pageSize: this.paging.pageSize,
        pageNo: this.paging.PageNo,
        personId: this.form.personId,
        personType: this.form.personType,
        personName: this.form.userName
        // "status": this.form.status
      })
      console.log(res)
      if (res.statusCode !== '200') return this.$message.error('获取列表失败')
      console.log(res)
      this.tabData = res.data.records
      this.total = +res.data.total
    },
    // 点击搜索按钮触发
    async search () {
      // 判断有输入账号
      // if (this.form.carNum === '') return this.$message.error('请输入车牌号码')
      this.tabData.forEach(item => {
        if (item.userName === this.form.userName) {
          this.form.personId = item.personId
        }
      })
      console.log(this.form.personId, this.form.personType)
      this.paging.PageNo = 1
      // 调接口，进行查询
      const { data: res } = await this.$http.post('/pmService/tInfoPersonStatusHistory/getTInfoPersonStatusHistoryList', {
        pageSize: this.paging.pageSize,
        pageNo: this.paging.PageNo,
        personId: this.form.personId,
        personType: this.form.personType,
        personName: this.form.userName
      })
      console.log(res)
      // 判断请求是否成功
      if (res.statusCode !== '200') return this.$message.error('查询失败')
      this.tabData = res.data.records
      this.total = +res.data.total
    },
    // 切换每页显示数量时触发
    handleSizeChange (newSize) {
      this.paging.pageSize = newSize
      this.getAllCar()
    },
    // 切换页码时触发
    handlePageNumChange (PageNum) {
      this.paging.PageNo = PageNum
      this.getAllCar()
    },
    // 点击查看详情按钮触发
    look (v) {
      this.isDisabled = true
      this.selectForm = v
      this.orderDialogFormVisible = true
    },
    // 点击编辑按钮触发
    edit (v) {
      this.isDisabled = false
      this.selectForm = _.cloneDeep(v)
      this.orderDialogFormVisible = true
    },
    // 点击删除按钮触发
    open (e) {
      this.$confirm(`是否确认删除：${e.carNum}`, '删除人员状态', {
        confirmButtonText: '确定删除',
        cancelButtonText: '取消删除',
        type: 'warning'
      }).then(async () => {
        try {
          const { data } = await this.$http.post('/pmService/tInfoPersonStatusHistory/deleteTInfoPersonStatusHistory', JSON.parse(JSON.stringify(e)))
          // 判断是否删除成功
          if (data.statusCode !== '200') return this.$message.error('删除失败')
          // 成功提示
          this.$message.success('删除成功')
          // 刷新视图
          this.getAllCar()
        } catch (e) {
          console.log(e)
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    // 点击详情确定框触发
    async sure () {
      // 判断是出于查看详情还是编辑
      if (this.isDisabled) { return this.orderDialogFormVisible = false } else {
        // 进行编辑操作
        const { data: res } = await this.$http.post('/pmService/tInfoPersonStatusHistory/updateTInfoPersonStatusHistory', JSON.parse(JSON.stringify(this.selectForm)))
        // 判断请求是否成功
        if (res.statusCode !== '200') return this.$message.error('修改失败')
        // 成功提示
        this.$message.success('修改成功')
        // 重新渲染数据
        this.getAllCar()
        this.orderDialogFormVisible = false
      }
    }
  }
}
</script>
<style lang="less" scoped>
.peohistoristate {
  width: 100%;
  height: 100%;
  //隐藏滚动条
  ::-webkit-scrollbar {
    // display: none; /* Chrome Safari */
  }
  .father-card {
    .btns {
      margin-bottom: 10px;
    }
    .tab {
      /deep/ .el-table {
        margin-bottom: 20px;
        .el-table__row--striped td {
          background-color: #e4eaec !important;
        }
        .current-row > td {
          background-color: #66b1ff !important;
        }
      }
      .el-pagination {
        margin-bottom: 0.25rem;
        margin-right: 0.25rem;
        text-align: right;
      }
    }
    .add-dialog,
    .order-dialog {
      .dialog-Title {
        color: #0097fe;
        font-size: 20px;
        font-weight: 700;
      }
      /deep/ .el-form {
        .subtitle {
          color: #0097fe;
          font-size: 14px;
          font-weight: 700;
          margin-bottom: 20px;
          padding-left: 6px;
          border-left: 5px solid #0097fe;
        }
        .el-row {
          display: flex;
          .el-form-item {
            flex: 1;
            display: flex;
            .el-form-item__content {
              flex: 1;
              .el-input {
                width: 100%;
                .el-input__inner {
                  width: 100%;
                }
              }
              .el-select {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}
</style>
